@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

:root{
  --main-color:rgb(62,142,190);
  --secondary-color:rgb(103,201,250);
  --bg-color:#f8f9fa;
}

body{
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: var(--bg-color);

  a{
    text-decoration: none;
    color:inherit;
  }

}

b, .bold{
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
}

.text-blue{
  color:var(--main-color);
}

.faq h4{
  margin-top: 30px;
}

.nav-link{
  font-size:17px;
  padding:0px 30px!important;
  margin-top: 5px;
  font-weight: bold;
}

.nav-link:hover{
  text-decoration: underline;
  transition:  0.3s ease-in;
}

.nav-link a , .navbar-brand a{
  text-decoration: none;
  color:inherit;
}


.navbar-brand:hover{
  color:var(--main-color);
}

.logo{
  font-size: 45px;
  color:var(--main-color);
}

.hero-bg{
  background-image: url('../public/imgs/herobg.jpg');
  background-size:cover;
  background-position-y: 70%;
  color: #fff;
  padding:150px 0px;

  .title{
    font-size:50px;
  }

  button{
    background-color: #fff;
    color:var(--main-color);
    padding:10px 15px;
    border-radius: 25px;
    border:none;
    font-size: 15px;
    font-weight: bold;
    margin-top: 35px;
    transition: 0.5s;
    border: solid 1px #fff;
  }
  
  button:hover{
    background-color: var(--main-color);
    color:#fff;
    transition: 0.5s;
  }
}

.k-points li {
  margin-bottom: 15px;
}

.rounded-sp{
  border-radius: 50%;
  height:100px;
  align-content: center;
  padding: 30px;
}


.navbar-toggler{
  align-self: center;
}
.circle-image 
{
img{
  height: 160px;
  border-radius: 50%;
  margin:40px auto;
  display: block;
  -webkit-box-shadow: 10px 10px 21px -7px rgba(0,0,0,0.5);
-moz-box-shadow: 10px 10px 21px -7px rgba(0,0,0,0.5);
box-shadow: 10px 10px 21px -7px rgba(0,0,0,0.5);
}

h4{
  margin-bottom: 25px;
}

}

footer {

  ul{
    margin: 0px;
    padding: 0px;
  }

li{
  list-style-type: none;
}

a:hover{
  text-decoration: underline  ;
}

}
:focus-visible{
  outline: none;
}

  label,input,textarea {
    display: block;
    font-size: 16px;
  }

  input[type=text], input[type=email], input[type=url]{

    width: 100%;  
    border:none;
    border-bottom: solid 1px var(--main-color);
    }

  select{
    width: 50%;
    padding: 5px 0px;
  }

  label{
    margin-top: 30px;
  }

  label:first-of-type{
    margin-top: 0px;
  }

  input[type=submit]{
    margin-top:30px;
    background-color:var(--main-color);
    color:#fff;
    padding:10px 15px;
    border-radius: 25px;
    border:none;
    font-size: 15px;
    font-weight: bold;
    margin-top: 25px;
    transition: 0.5s;
    border: solid 1px #fff;
  }


  .social-img{

    width:35px;
    filter: invert(100%);
    margin-right: 15px;
  }

  .navbar-toggler{
    border: none;
  }

  .navbar-toggler:focus {
    box-shadow: none;
    outline: none;
}
  


@media (max-width:1400px) and (min-width:1200px)

{


  .nav-link {
    padding: 0px 15px !important;
}

}
@media (max-width:1200px) and (min-width:993px)

{

  .circle-image 
{
img{
  height: 120px;
}

h3{
  font-size:24px;
}

h4{
  margin-bottom: 20px;
  font-size: 20px;
}

}

}

@media (max-width:1200px){
  .logo{
    font-size: 25px;
  }
  .nav-link{
    font-size:15px;
  }

  .hero-bg{
    padding:75px 0px;
    h2{
      font-size: 20px;
    }
  }
}

@media (max-width:993px){

.circle-image {
img{
  height: 80px;
}
h4{
  margin-bottom:10px;
}

}

.hero-bg{
  background-size:auto;
  background-position-x: 10%;
  background-position-y:50%;
}

.nav-link{
  padding:0px!important;
}
}

@media (max-width:768px)
{
  .rounded-sp{
  border-radius: 25;
  height:100px;
  align-content: center;
  padding: 30px;
  margin-bottom:15px!important;
}

.logo{
  font-size: 20px;
}
}

@media (max-width:400px){


  .circle-image img{
    height: 60px;
  }

}


